import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "gatsby";
import React, {useEffect, useState} from "react";
import { BASE_URL } from "../../assets/js/_variables";

// Componentes
import Layout from "../../components/Layout";
import Slider from "../../components/Slider";

const teatro_vallarta_mayahuel = () => {
  const [lang, setLang] = useState()
  
  const txt = {
    en: {
      pageTitle: 'TEATRO VALLARTA MAYAHUEL | HB Privileged',
      title: 'TEATRO VALLARTA MAYAHUEL',
      subtitle: 'TOUR OVERVIEW',
      text: "Our destination is also culture and the cradle of the Vallartan culture is our theater. Located right in the heart of downtown, Vallarta Theater presents the show Mayahuel. Mayahuel is the Godess of Agave, the plant of which we extract tequila, 100% mexican made drink, and this is the representation of Her legend. Male and female dancers dressed in prehispanic costumes will do a show of dancing, fire and stunts. If you wish, you can enjoy the bar during the show. It ́s a really impressive play that will get you closer to the prehispanic culture by exploring the 4 basic elements, Earth, Wind, Fire and Water. At the end take a picture with the artists and join us for a 5 starts dinner on the beach. If you are looking for something different, know the historyand legends of our culture and to have an experience to share with your loved ones in your place of origin, come to the Vallarta Theater to see Mayahuel. You will definitely not regret it.",
      frequency: 'Frequency:',
      hours: 'Hours:',
      duration: 'Duration:',
      btn: 'Book now',
    },
    es: {
      pageTitle: 'TEATRO VALLARTA MAYAHUEL | HB Privileged',
      title: 'TEATRO VALLARTA MAYAHUEL',
      subtitle: 'RESUMEN DEL TOUR',
      text: 'Nuestro destino también es cultura y la cuna de la cultura vallartense es nuestro Teatro. Localizado en el pleno corazón del centro, el teatro Vallarta presenta la obra Mayahuel. Mayahuel es la Diosa del Agave, la planta de la que extraemos el tequila, bebida 100% mexicana y esta es la representación de su leyenda.    Bailarines y bailarinas ataviados en prendas prehispánicas harán un espectáculo de danza, fuego y acrobacias. Si lo deseas, puedes disfrutar de la barra de bebidas durante la función. Es un show realmemte impresionante que te acercará a la cultura prehispánica mediante la exploración de los 4 elementos, Tierra, Aire, Fuego y Agua. Al finalizar, sácate una foto con los artistas y acompáñanos a una cena 5 estrellas en la playa. Si buscas algo diferente, conocer la historia y leyendas de nuestra cultura y tener una experiencia para recordar con tus seres queridos en tu lugar de origen, ven a al Teatro Vallarta a ver Mayahuel. Definitivamente no te vas a arrepentir.',
      frequency: 'Frecuencia:',
      hours: 'Horas:',
      duration: 'Duración:',
      btn: 'Reserva este tour',
    }
  }

  const images = [
    `${BASE_URL}/tours/teatro-vallarta-mayahuel1.jpg`,
    `${BASE_URL}/tours/teatro-vallarta-mayahuel2.jpg`,
    `${BASE_URL}/tours/teatro-vallarta-mayahuel3.jpg`,
  ]

  const getLang = () => {
    if (typeof window !== 'undefined') {
      return localStorage.getItem('lang')
    }
  }

  useEffect(() => {
    if(getLang() === 'es') {
      setLang('es')
    } else {
      setLang('en')
    }
  }, [])

  return (
    <Layout pageTitle={ lang === 'es' ? txt.es.pageTitle : txt.en.pageTitle }>
      <div>
        {/*</div><div className="h-80 overflow-hidden">*/}
        <div className="h-70 overflow-hidden">
          <img src={`${BASE_URL}/banners/teatro-vallarta-mayahuel.jpg`} className="w-full object-cover" alt="" />
        </div>
        <div className="section">
          <h2 className="text-2xl md:text-4xl font-bold mb-2">
            { lang === 'es' ? txt.es.title : txt.en.title }
          </h2>
          <div className="mb-4">
            <FontAwesomeIcon className="text-amber-400" icon={faStar} />
            <FontAwesomeIcon className="text-amber-400" icon={faStar} />
            <FontAwesomeIcon className="text-amber-400" icon={faStar} />
            <FontAwesomeIcon className="text-amber-400" icon={faStar} />
            <FontAwesomeIcon className="text-amber-400" icon={faStar} />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <h3 className="text-xl md:text-2xl font-semibold mb-4">
                { lang === 'es' ? txt.es.subtitle : txt.en.subtitle }
              </h3>
              <p className="normal-text text-justify mb-4">
                { lang === 'es' ? txt.es.text : txt.en.text }
              </p>
              <p className="normal-text">
                { lang === 'es' ? txt.es.frequency : txt.en.frequency }
              </p>
              <p className="normal-text">
                { lang === 'es' ? txt.es.hours : txt.en.hours }
              </p>
              <p className="normal-text">
                { lang === 'es' ? txt.es.duration : txt.en.duration }
              </p>
            </div>

            <div className="">
              <div className="rounded overflow-hidden">
                <Slider images={images} />
              </div>
              
              <div className="text-center py-8">
                <Link
                  className="text-center w-1/2 mt-16 text-xl font-bold bg-sky-800 text-neutral-50 py-2 px-4 rounded-full" 
                  to={`${BASE_URL}/contacto`}
                >
                  { lang === 'es' ? txt.es.btn : txt.en.btn }
                </Link>
              </div>
            </div>
          </div>
        </div>

      </div>
    </Layout>
  );
}
 
export default teatro_vallarta_mayahuel;